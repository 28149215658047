export const environment = {
  production: true,
  environment: 'production',
  company_name: 'ESET East Africa',
  auth_token:
    'eyJhbGciOiJIUzUxMiJ9.eyJhcHBsaWNhdGlvbiI6ImxvZ2luIiwidG9rZW4iOiJOTUZGSFhtZEpOUTZaRmhRMFVVa2h5cUhpIn0.R0oewN2CoNezI8qBibSOYM1LN1kiuT3Cv9GCOftmIoX0U0NhCYpKMpgdmYooby73j0DPbVA5VnnqNDBkFpic9g',
  api_url: 'https://authenticate.esetafrica.com',
  transact_url: 'https://transact.esetafrica.com',
  cookie_scope: '',
  namespace: '.esetafrica.com',
  eset_support_email: 'support@esetafrica.com',
  eset_partner_email: 'partner@esetafrica.com',
  eset_website: 'https://www.eset.com/afr',
  passphrase_regex: /((\w){1,}(\ |\-|\,|\.|\_)(\w){1,}){2,}/i,
  uppercase_regex: /[A-Z]{1}/,
  numeric_regex: /\d{1}/,
  xkcd_regex: /(correcthorse|correct\ horse)/i,
  password_min_length: 8,
  passphrase_min_length: 16,
  min_zxcvbn_score: 3,
  static_domain: 'https://static.esetafrica.com/',
};
